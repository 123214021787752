<template>
  <div :style="{
    background: 'linear-gradient( 356deg, #E1EEFF87 20%, #E1EEFF87 1%, #FFFFFF 35%, #FFFFFF 0%)',
  }" class="pt-10">
    <v-layout wrap justify-center  >
    <v-flex xs11 sm11 md11 lg10  data-aos="zoom-in-up"
    data-aos-ease="ease"
    data-aos-duration="1500"
    data-aos-delay="500" >
      <v-flex xs12 pb-12 v-for="(item, i) in items" :key="i" >
        <v-card outlined elevation="5" rounded="lg" class="pa-6" >
          <v-layout wrap justify-center >
            <!-- Image on the left -->
            <v-flex xs12 sm6 md4 lg3 >
              <v-img :src="item.img"  height="200px"></v-img>
            </v-flex>

            <!-- Content on the right -->
            <v-flex xs12 sm12 md8 lg9 px-lg-3 >
              <v-card-title> <span style="font-family: OutfitBold; font-size: 20px; color: black">{{item.title}}</span></v-card-title>
              <v-card-text>
               
                <!-- Wrap the rating and span in a flex container -->
                <!-- <div style="display: flex; align-items: center;">
                 
                  <v-rating 
                    style="margin-left: -10px;"
                    v-model="rating"
                    background-color="orange lighten-3"
                    color="orange"
                    small
                    half-increments 
                  ></v-rating>
                  
                  <span style="font-family: OutfitBold; font-size: 17px; color: black">{{item.review}}</span>&nbsp;&nbsp;<span style="font-family: JostRegular; font-size: 15px; color: grey">{{item.fullreview}}</span>
                </div> -->
                <p style="font-family: JostRegular; color: black; font-size: 15px; text-align:justify">{{item.content}}</p>
              </v-card-text>

              <v-layout wrap justify-end >
                  <v-flex xs11 sm4 md3 lg2  >
                    <router-link to="/coursesingleview" style="text-decoration: none">
                    <v-btn class="px-5 pa-4" color="primary" small>
                     
                        <span style="color: white; text-transform: capitalize;">Course Details</span>
                      
                    </v-btn>
                  </router-link>
                  </v-flex>
                </v-layout>

              <!-- Button Section -->
              <!-- <v-layout wrap justify-start>
            
             <v-flex xs2 pb-5>
                  <v-btn class="ml-3 pa-4" color="#E6EFF9" style="border-radius: 8px; background-color: #E6EFF9 !important; color: black !important;" small>
                    <v-icon small color="blue">mdi-clock-outline</v-icon>
                    &nbsp; <span style="color: black; text-transform: capitalize;">{{item.months}}</span>
                  </v-btn>
                </v-flex>

          
                <v-flex pb-5>
                  <v-btn class="pa-4" color="#E6EFF9" style="border-radius: 8px;" small>
                    <v-icon small color="blue">mdi-book-open-outline</v-icon>
                    &nbsp;<span small style="color: black; text-transform: capitalize;">{{item.chapter}}</span>
                  </v-btn>
                </v-flex>

               
                <v-layout wrap justify-end>
                  <v-flex xs1 pb-5>
                    <v-btn class="px-5 pa-4" color="primary" small>
                      <router-link to="/coursesingleview" style="text-decoration: none">
                        <span style="color: white; text-transform: capitalize;">Course Details</span>
                      </router-link>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-layout> -->
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-flex>
    </v-layout>


  </div>
</template>





              <!-- <v-layout wrap justify-end>
                <v-flex xs11 sm4 md3 lg2 pb-5>
                  <v-btn class="px-5 pa-4" color="primary" small>
                    <router-link to="/coursesingleview" style="text-decoration: none">
                      <span style="color: white; text-transform: capitalize;">Course Details</span>
                    -->

  
  <script>
  export default {
   
    data() {
      return {
        items: [
          {
            img: require("./../../assets/images/ger.png"),
            title: "German course - LEVELS A1-B2",
            // review: "4.5",
            // fullreview: "(73 Reviews)",
            content:
              "Achieve fluency in German through our meticulously crafted four-step language course, guiding you from foundational communication to advanced proficiency. Develop confidence in navigating daily scenarios, explore topics such as city life and holidays, and refine persuasive speech to enhance your linguistic expertise.",
            // months: "3 Months",
            // chapter: "32 Chapters",
          },

          
          // {
          //   img: require("./../../assets/images/imgslider3.png"),
          //   title: "IELTS Course Training",
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",
          //   content:
          //     "rom foundational language proficiency to advanced exam strategies, our program equips you with the tools needed to excel in all sections of the test. Gain mastery in speaking, listening, reading, and writing, and confidently approach the exam with our comprehensive preparation resources and expert support.",
          //   months: "3 Months",
          //   chapter: "32 Chapters",
          // },
        //   {
        //     img: require("./../../assets/images/cbt.png"),
        //     title: "Tally Prime - Balance Is The Key",
        //     review: "4.5",
        //     fullreview: "(73 Reviews)",
        //     content:
        //       "Discover the power of TALLYPRIME in our dynamic course designed for comprehensive business management. From accounting to payroll, delve into its versatile features and learn to optimize operations effortlessly. Empower yourself with the skills to navigate complex financial tasks seamlessly, ensuring your business achieves balance and efficiency in every aspect.",
        //     months: "3 Months",
        //     chapter: "32 Chapters",
        //   },
        ],
      };
    },

    
  };
  </script>
  